// FeaturesSection.js
import React from 'react';
import '@fortawesome/fontawesome-free/css/all.min.css';
import '../styles/FeaturesSection.css';

function FeaturesSection() {
  return (
    <section id="features" className="features-section">
      <h2>Why Choose KonnectTaps?</h2>
      <div className="feature" data-aos="fade-up">
        <div className="feature-text">
          <h3>Simple Page Editor</h3>
          <p>
            Our user-friendly page editor allows you to create and customize your personal digital page effortlessly. With drag-and-drop functionality, you can add links, images, and content without any coding knowledge. In just under 5 minutes, your personalized page will be live and ready to share.
          </p>
        </div>
        <img src="/images/pc/editor.png" alt="Simple Page Editor" />
      </div>
      <div className="feature" data-aos="fade-up">
        <div className="feature-text">
          <h3>Advanced Analytics</h3>
          <p>
            Gain deep insights into your networking effectiveness with our comprehensive analytics dashboard. Track page visits, link clicks, and user interactions in real-time. Understand your audience better with detailed reports, and tailor your content to maximize engagement.
          </p>
        </div>
        <img src="/images/pc/analytics.png" alt="Smart NFC Cards" />
      </div>
      <div className="feature" data-aos="fade-up">
        
        <div className="feature-text">
          <h3>Smart NFC Cards</h3>
          <p>
            Bridge the gap between in-person networking and online engagement with our innovative smart NFC business cards. Share your digital page instantly with a single tap on any NFC-enabled smartphone. Make memorable first impressions and stand out from the crowd.
          </p>
        </div>
        <img src="/images/pc/card.png" alt="Smart NFC Cards" />
      </div>
      <div className="small-features">
        <div className="small-feature" data-aos="fade-up">
          <i className="fas fa-lock fa-3x"></i>
          <h4>Privacy Controls</h4>
          <p>
            You have full control over what information you share online. Customize your privacy settings to share as much or as little as you like. Keep certain details private on your digital page and reveal more when networking in person.
          </p>
        </div>
        <div className="small-feature" data-aos="fade-up">
          <i className="fas fa-link fa-3x"></i>
          <h4>Custom URL</h4>
          <p>
            Simplify your online presence with a custom URL: konnecttaps.com/YourName. An easy-to-remember link that you can share anywhere, making it simple for others to find and connect with you.
          </p>
        </div>
      </div>
    </section>
  );
}

export default FeaturesSection;