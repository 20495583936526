// Navbar.js
import React, { useEffect, useState } from 'react';
import { Link } from 'react-scroll';
import '../styles/Navbar.css';

function Navbar() {
  const [scrolled, setScrolled] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const offset = window.scrollY;
      setScrolled(offset > 0);
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <nav className={`navbar ${scrolled ? 'scrolled' : ''}`}>
      <div className="navbar-left">
        <div className="navbar-toggle" onClick={toggleMenu}>
          <i className="fas fa-bars"></i>
        </div>
        <div className="navbar-logo">
          <img
            src={scrolled ? '/images/logo.png' : '/images/logo_w.png'}
            alt="KonnectTaps Logo"
          />
        </div>
      </div>
      <ul className={`navbar-menu ${menuOpen ? 'active' : ''}`}>
        <li>
          <Link to="hero" smooth={true} duration={500} onClick={toggleMenu}>
            Home
          </Link>
        </li>
        <li>
          <Link to="features" smooth={true} duration={500} onClick={toggleMenu}>
            Features
          </Link>
        </li>
        <li>
          <Link to="pricing" smooth={true} duration={500} onClick={toggleMenu}>
            Pricing
          </Link>
        </li>
        <li>
          <Link to="aboutus" smooth={true} duration={500} onClick={toggleMenu}>
            About Us
          </Link>
        </li>
        <li>
          <Link to="contact" smooth={true} duration={500} onClick={toggleMenu}>
            Contact
          </Link>
        </li>
      </ul>
    </nav>
  );
}

export default Navbar;