// App.js
import React, { useEffect } from 'react';
import Navbar from './components/Navbar';
import HeroSection from './components/HeroSection';
import FeaturesSection from './components/FeaturesSection';
import HowItWorksSection from './components/HowItWorksSection';
import PricingSection from './components/PricingSection';
import AboutUsSection from './components/AboutUsSection';
import TeamSection from './components/TeamSection';
import WaitlistSection from './components/WaitlistSection';
import ContactSection from './components/ContactSection';
import Footer from './components/Footer';
import AOS from 'aos';
import 'aos/dist/aos.css';

function App() {
  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  return (
    <div>
      <Navbar />
      <HeroSection />
      <FeaturesSection />
      <HowItWorksSection />
      <PricingSection />
      <AboutUsSection />
      <TeamSection />
      {/* <WaitlistSection /> */}
      <ContactSection />
      <Footer />
    </div>
  );
}

export default App;